import React from "react";
import "./reuse.css";
import { getList } from "../GlobalVars/GetFunction";

export const Load = ({ list, page, set }) => {
  const url = process.env.REACT_APP_SERVER;

  const load = async () => {
    const datas = await getList(list, page, url);
    datas?.map((item, _) => {
      return set((prev) => [...prev, item]);
    });
  };

  return (
    <button
      onClick={load}
      className="readMore"
      style={{ margin: "3rem auto", borderRadius: "5px" }}
    >
      Load More
    </button>
  );
};
