import React from "react";
import "./reuse.css";

export const CommunityCard = ({ data }) => {
  const url = process.env.REACT_APP_SERVER;
  return (
    <div
      className="commCard"
      style={{ backgroundImage: `url("${url}${data?.image}")` }}
    >
      <div className="commContent">
        <h1>{data?.title}</h1>
        <br className="commBr"/>
        <div className="danger" dangerouslySetInnerHTML={{ __html: data?.content }}></div>
      </div>
    </div>
  );
};
