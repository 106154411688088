import React from "react";
import "./home.css";
import industry from "./industry.json";
import { IndustryCard } from "../ReUsables/IndustryCard";

export const Industry = () => {
  return (
    <div className="industryhold">
      <h1>Who We Serve</h1>
      <br />
      <br/>
      <div className="industries">
        {industry?.map((data, index) => (
          <IndustryCard data={data} index={index} key={index} />
        ))}
      </div>
    </div>
  );
};
