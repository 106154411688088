import React, { useContext } from "react";
import "./reAdmin.css";
import { MdDelete } from "react-icons/md";
import { deleteUnits } from "../GlobalVars/AdminPost";
import { Changes } from "../GlobalVars/GlobalVars";
export const BlogEditableCard= ({ relation,data, url, style, change, setChange }) => {
  const { userData } = useContext(Changes);

  console.log(data)

  return (
    <div className="commCardAdmin adminCard" style={style}>
      <button
        className="adminDel"
        onClick={async () => {
          await deleteUnits(relation, data, userData?.token);
          return setChange(!change);
        }}
      >
        <MdDelete />
      </button>
      <div className="imageHolderServ">
        <img src={url + data?.image} alt="" className="delComImg" />
      </div>
      <div className="textOfServ">
        <h3>{data?.title}</h3>
      </div>
    </div>
  );
};
