import React from "react";
import "./reAdmin.css";
import { useNavigate } from "react-router-dom";

export const AddBtn = ({ linkTo }) => {
  const nav = useNavigate();

  return (
    <button className="addBtn" onClick={() => nav(linkTo)}>
      +
    </button>
  );
};
