import React from "react";
import "./reuse.css";

export const TopBox = ({ title, image, styles, isContact }) => {
  return (
    <div className={`TopBox`}>
      <img src={image} alt="" />
      <br />
      <div className={`pageTitle `} style={styles}>
        <h1 className={isContact && "contactTopBox"}>{title}</h1>
      </div>
    </div>
  );
};
