import React, { useEffect, useState } from "react";
import "./blog.css";
import { Blogs } from "../GlobalVars/GlobalVars";
import { getFunction, getList } from "../GlobalVars/GetFunction";
import { TopBox } from "../ReUsables/TopBox";
import { GridControl } from "../ReUsables/GridControl";
import { BlogCard } from "../ReUsables/BlogCard";
import { Load } from "../ReUsables/Load";

export const Blog = () => {
  const [data, setData] = useState();
  const [blogList, setBlogList] = useState([]);
  const [grid, setGrid] = useState(false);
  const url = process.env.REACT_APP_SERVER;

  useEffect(() => {
    const handleReqs = async () => {
      await getFunction("blog", setData, url);
      const blogs = await getList("blog-list", 0, url);
      setBlogList(blogs);
    };
    handleReqs();
  }, [url]);

  return (
    <Blogs.Provider value={{ data, blogList, setBlogList }}>
      <div className="main">
        <TopBox
          title={data?.unstructs?.title}
          image={`${url}${data?.unstructs?.primaryImage}`}
        />
        <div className="subTitle">
          <h2>Explore Our Latest Blogs</h2>
          <GridControl curr={grid} set={setGrid} />
        </div>
        <div className="blogContainer">
          {blogList?.map((item, index) => (
            <BlogCard data={item} grid={grid} key={index} set={setGrid} />
          ))}
        </div>
        <br />
        <center>
          <Load
            list={"blog-list"}
            page={Math.ceil(blogList?.length / 6)}
            set={setBlogList}
          />
        </center>
      </div>
    </Blogs.Provider>
  );
};
