import React from "react";
import "./nav.css";
import { NavLink } from "react-router-dom";

export const AdminNav = () => {
  return (
    <div className="usernav">
      <center>
        {/* logo */}
        <img src="/image/logo.png" alt="" />

        {/* links */}
        <div className="linkHolders">
          {/* home */}
          <NavLink to="/profile" className={"useLink"}>
            <button className="adminNavigation">Home</button>
          </NavLink>
          {/* service */}
          <NavLink to="/profile/services" className={"useLink"}>
            <button className="adminNavigation">Services</button>
          </NavLink>
          {/* products */}
          <NavLink to="/profile/products" className={"useLink"}>
            <button className="adminNavigation">Products</button>
          </NavLink>
          {/* about */}
          <NavLink to="/profile/about" className={"useLink"}>
            <button className="adminNavigation">About</button>
          </NavLink>
          {/* contact */}
          <NavLink to="/profile/contact" className={"useLink"}>
            <button className="adminNavigation">Contact</button>
          </NavLink>
          {/* blogs */}
          <NavLink to="/profile/blogs" className={"useLink"}>
            <button className="adminNavigation">Blogs</button>
          </NavLink>
          {/* workshop */}
          <NavLink to="/profile/workshops" className={"useLink"}>
            <button className="adminNavigation">Workshops</button>
          </NavLink>
          {/* communities */}
          <NavLink to="/profile/community" className={"useLink"}>
            <button className="adminNavigation">Community</button>
          </NavLink>
          {/* change password */}
          <NavLink to="/profile/change_password" className={"useLink"}>
            <button className="adminNavigation">Change Password</button>
          </NavLink>          
          <NavLink to="/profile/open studio" className={"useLink"}>
            <button className="adminNavigation">Open Studio</button>
          </NavLink>
          <NavLink to="/profile/users" className={"useLink"}>
            <button className="adminNavigation">Users</button>
          </NavLink>
          <NavLink to="/profile/footer" className={"useLink"}>
            <button className="adminNavigation">Footer</button>
          </NavLink>
          {/* back to home */}
          <NavLink to="/" className={"useLink"}>
            <button className="adminNavigation">Back to Website</button>
          </NavLink>
        </div>
      </center>
    </div>
  );
};
