import React, { useContext, useEffect, useState } from "react";
import "./about.css";
import { Changes } from "../GlobalVars/GlobalVars";
import { getFunction } from "../GlobalVars/GetFunction";
import { TopBox } from "../ReUsables/TopBox";
import { Paras } from "../ReUsables/Paras";

export const About = () => {

  const [data, setData] = useState();
  const { change } = useContext(Changes);
  const url = process.env.REACT_APP_SERVER;

  console.log(data);

  const style = {
    maxWidth: "800px",
    margin: "20px auto",
  };

  useEffect(() => {
    getFunction("about", setData, url);
  }, [url, change]);

  return (
    <div className="main">
      <TopBox
        title={data?.unstructs?.title}
        image={`${url}${data?.unstructs?.primaryImage}`}
        styles={style}
      />
      <br />
      <div className="aboutContent" style={style}>
        {/* datas info contents */}
        {data?.about?.map((item, index) => (
          <Paras data={item} key={index} />
        ))}
      </div>
      <br/>
      <br/>
    </div>
  );
};
