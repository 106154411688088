const url = process.env.REACT_APP_SERVER;

export const sendImage = async (e, relation, isSlide, idx, set) => {
  const token = JSON.parse(sessionStorage.getItem("userdata"))?.token;
  if (!token) {
    return alert("You are not Admin !");
  }

  // file
  const file = e?.target?.files[0];
  // extention
  const ext = await file?.name?.split(".")?.pop()?.toLowerCase();
  if (ext !== "jpg" && ext !== "jpeg" && ext !== "png") {
    return alert("Invalid File Type");
  }
  const objUrl = URL.createObjectURL(file);
  // Update state with image URL
  if (isSlide) {
    set((prevState) => ({
      ...prevState,
      [`img${idx+1}`]: objUrl,
    }));
  } else {
    set(objUrl);
  }

  console.log(file);

  const newFormData = new FormData();
  newFormData.append("image", file);

  try {
    // Upload image
    await fetch(`${url}/admin-post-image/${relation}`, {
      method: "PUT",
      headers: {
        authorization: `Bearer ${token}`,
        isslide: isSlide,
        idx,
      },
      body: newFormData,
    });
  } catch (error) {
    console.error("Error uploading image:", error);
  }
};

export const editUnits = async (relation, data, token) => {
  try {
    const res = await fetch(`${url}/admin-put/${relation}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ data }),
    });

    if (res.ok) {
      return true;
    } else {
      throw new Error(res?.status);
    }
  } catch (error) {
    console.log(error);
    return false
  }
};

export const deleteUnits = async (relation, data, token) => {
  if (!token) {
    return alert("You Are Not Authorized");
  }

  const confirm = window.confirm(`Delete this ${relation}`);

  if (!confirm) {
    return;
  }

  console.log(confirm)
  try {
    const res = await fetch(`${url}/admin-delete/${relation}`, {
      method: "DELETE",
      headers: {
        authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ _id: data?._id }),
    });

    console.log(res);

    if (res?.ok) {
      return alert(`This ${relation} is deleted!`);
    }
  } catch (error) {
    console.log(error);
    return alert("Internal Error");
  }
};
