import React from 'react'
import "./admin.css";
import { AdminNav } from '../Nav/AdminNav';

export const MainAdminBox=({page})=>{
    return(
        <div className="main" style={{position:"relative", padding:"0", minWidth:"calc(375px)"}}>
            <AdminNav/>
            {page}
        </div>
    )
}