import React, { useEffect, useState } from "react";
import "./contact.css";
import { TopBox } from "../ReUsables/TopBox";
import { getFunction } from "../GlobalVars/GetFunction";
import { ContactForm } from "./ContactForm";

export const Contact = () => {
  const [data, setData] = useState();

  const url = process.env.REACT_APP_SERVER;

  useEffect(() => {
    getFunction("contact", setData, url);
  }, [url]);

  return (
    <div className="main">
      {/* top box */}
      <TopBox
        image={`${url}${data?.unstructs?.primaryImage}`}
        isContact={true}
        title={"Contact"}
      />

      <div className="contactContent">
        <ContactForm />

        <div
          className="embededmap"
          dangerouslySetInnerHTML={{ __html: data?.unstructs?.embeds }}
        />
      </div>
    </div>
  );
};
