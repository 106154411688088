import React, { useContext, useEffect, useState } from "react";
import "./nav.css";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { GiHamburgerMenu } from "react-icons/gi";
import { Changes } from "../GlobalVars/GlobalVars";

export const Nav = () => {
  const links = ["services", "about", "contact", "blog", "open studio"];
  const subLink = [
    "services",
    "products",
    "workshops",
    "about",
    "community",
    "contact",
    "blog",
    "open studio",
  ];

  const { login } = useContext(Changes);
  const location = useLocation();

  const [className, setClassName] = useState("");
  const nav = useNavigate();

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 100) {
        setClassName("scrolled");
      } else {
        setClassName("");
      }
    });
  });

  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);


  return (
    <div
      className={`navBox ${className}`}
      style={{
        display: location?.pathname?.includes("/profile") ? "none" : "flex",
      }}
    >
      <div className="navbar">
        {/* logo */}
        <NavLink to="/">
          <img
            src="/image/logo.png"
            alt=""
            className={` ${!className ? "unScolledLogo" : "logo"}`}
          />
        </NavLink>

        {/* nav to links */}
        <div className="full">
          <nav className="nav">
            {links?.map((data, idx) => {
              if (data === "about" || data === "services") {
                return (
                  <button className="mainMenu" key={idx}>
                    {data}
                    <div className="menuHolding">
                      <div className="menus topMenu" style={{textAlign:"left"}}>
                        {data === "services" && (
                          <NavLink to={"/our-products"} className="sublink">
                            Our Products
                          </NavLink>
                        )}
                        <NavLink
                          to={data === "about" ? `/about` : "/services"}
                          className="sublink"
                        >
                          {data === "about"
                            ? "about karnor"
                            : "End To End Services"}
                        </NavLink>
                        <NavLink
                          to={data === "about" ? `/community` : "/workshops"}
                          className="sublink"
                        >
                          {data === "about" ? "community" : "The Workshops"}
                        </NavLink>
                      </div>
                    </div>
                  </button>
                );
              } else {
                return (
                  <NavLink className={`navlink mainMenu`} to={`/${data}`} key={idx}>
                    {data}
                  </NavLink>
                );
              }
            })}
            
            <button className="mainMenu">
              Shop
              <div className="menuHolding">
                <div className="menus topMenu">
                  <a href="https://zekodesigns.com/">
                    Visit our partner <br/> site Zeko Designs
                  </a>
                </div>
              </div>
            </button>
          </nav>

          {/* login button */}
          <button
            className="login"
            onClick={() => {
              nav(`/${login}`);
            }}
            style={{ textTransform: "capitalize" }}
          >
            {login}
          </button>
        </div>

        {/* mini */}
        <button className="menu">
          <GiHamburgerMenu />

          {/* menu items */}
          <div className="menuHolding">
            
          <div className="menus">
            {subLink?.map((data, idx) => (
              <NavLink to={`/${data!=="products"?data:"our-products"}`} className="sublink" key={idx}>
                {data}
              </NavLink>
            ))}
            <NavLink to={`/${login}`} className="sublink">
              {login}
            </NavLink>
            <a href="https://zekodesigns.com/" className="sublink">
              Shop
            </a>
          </div>
          </div>
        </button>
      </div>
    </div>
  );
};
