import React, { useEffect, useState } from "react";
import "./workshop.css";
import { TopBox } from "../ReUsables/TopBox";
import { getFunction, getList } from "../GlobalVars/GetFunction";
import { WrkShp } from "../ReUsables/WrkShp";
export const Workshop = () => {
  const url = process.env.REACT_APP_SERVER;

  const [data, setData] = useState();
  const [list, setList] = useState([]);

  useEffect(() => {
    getFunction("workshop", setData, url);
    const getData = async () => {
      const fetched = await getList("workshop", 0, url);
      return setList(fetched);
    };
    getData();
  }, [url]);

  return (
    <div className="main workshopMain">
      <TopBox
        title={data?.unstructs?.title}
        image={`${url}${data?.unstructs?.primaryImage}`}
      />

      <div
        className="innerContents"
        dangerouslySetInnerHTML={{ __html: data?.unstructs?.subtitle }}
      ></div>
      {/* mapping data */}
      <div className="workHolder">
        {list?.map((item, idx) => (
          <WrkShp
            title={item?.title}
            image={`${url}${item?.image}`}
            content={item?.content}
            key={idx}
          />
        ))}
      </div>
    </div>
  );
};
