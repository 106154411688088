import React, { useEffect, useState } from "react";
import { ServiceData } from "../GlobalVars/GlobalVars";
import { TopBox } from "../ReUsables/TopBox";
import { getFunction } from "../GlobalVars/GetFunction";
import { ServCard } from "../ReUsables/ServCard";

export const Products = () => {
  const url = process.env.REACT_APP_SERVER;

  const [data, setData] = useState();
  console.log(data);

  useEffect(() => {
    getFunction("product", setData, url);
  }, [url]);

  return (
    <ServiceData.Provider value={data}>
      <div className="main">
        <TopBox
          title={data?.unstructs?.title}
          image={`${url}${data?.unstructs?.primaryImage}`}
        />
        <div
          className="innerContents"
          dangerouslySetInnerHTML={{ __html: data?.unstructs?.subtitle }}
        ></div>

        {/* breaking line */}
        <br />
        <br />

        {/* mapping services card */}

        <div
          className="servHolder"
          style={{ margin: "0 auto", width: "calc(80%)", textAlign: "center" }}
        >
          {data?.product?.map((item, index) => (
            <ServCard
              key={index}
              data={item}
              url={url}
              isProduct={true}
              style={{ margin: "2rem", height:"fit-content" }}
            />
          ))}
        </div>

        <br />
        <br />
        <br />
      </div>
    </ServiceData.Provider>
  );
};
