const url = process.env.REACT_APP_SERVER;

export const PostFunctions = async (post, data, next) => {
  try {
    const res = await fetch(`${url}/post/${post}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    if (!res?.ok) {
      const parse = await res?.json();
      return alert(parse?.msg);
    } else {
      const parsed = await res?.json();
      const userInfos = {
        token: parsed?.token,
        data: parsed?.data,
      };

      await next(userInfos);
      return alert(parsed?.msg);
    }
  } catch (error) {
    console.log(error);
    alert(error?.msg);
  }
};
