import React, { useContext, useEffect, useState } from "react";
import { AdminTopHandle } from "../../ReusableAdmin/AdminTopHandle";
import { Changes } from "../../GlobalVars/GlobalVars";
import { getFunction } from "../../GlobalVars/GetFunction";
import "./abtEdit.css";
import { deleteUnits } from "../../GlobalVars/AdminPost";

export const EditCnt = () => {
  const [data, setData] = useState();
  const [contactList, setContactList] = useState();
  const url = process.env.REACT_APP_SERVER;
  const { change, userData, setChange } = useContext(Changes);
  const [filter, setFilter] = useState("");

  useEffect(() => {
    getFunction("contact", setData, url);
    getFunction("contact-admin", setContactList, url);
  }, [url, change]);

  return (
    <div className="adminEdit">
      <AdminTopHandle data={data?.unstructs?.primaryImage} relate={"contact"} />
      <br />

      <div className="innerContents">
        <h1 style={{ color: "var(--darkblue)" }}>Contact Us</h1>
        <br />
        <br />
        <input
          className="search"
          placeholder="Filter"
          value={filter}
          onChange={(e) => {
            setFilter(e?.target?.value);
          }}
        />
        <br />
        <br />
        <br />
        <br />
        {contactList?.adminContactList?.map((item, idx) => {
          if (
            item?.subject?.toLowerCase()?.includes(filter?.toLowerCase()) ||
            item?.note?.toLowerCase()?.includes(filter?.toLowerCase())
          ) {
            return (
              <div className="contactInfo" key={idx}>
                <b>Email : </b>
                {item?.email}
                <br />
                <br />
                <b>Name :</b> {item?.name}
                <br />
                <br />
                <b>Company :</b> {item?.company || "Not Added"} <br />
                <br />
                <b>Subject : </b> {item?.subject}
                <br />
                <br />
                <b>Message :</b> {item?.note}
                <br />
                <br />
                <br />
                <button
                  className="adminDel"
                  style={{
                    position: "unset",
                    margin: 0,
                    fontSize: "1.8rem",
                    padding: "1rem",
                  }}
                  onClick={async () => {
                    await deleteUnits("contact", item, userData?.token);
                    return setChange(!change);
                  }}
                >
                  Delete
                </button>
              </div>
            );
          }
          return <></>;
        })}
      </div>
    </div>
  );
};
