import React, { useContext, useEffect, useRef, useState } from "react";
import "./home.css";
import { HomeData } from "../GlobalVars/GlobalVars";
export const Exp = () => {
  const { data } = useContext(HomeData);

  const [exp, setExp] = useState(0);
  const [happyClients, setHappyClients] = useState(0);
  const [inView, setInView] = useState(false);

  const viewRef = useRef(null);
  
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setInView(true);
          observer.disconnect(); // Disconnect the observer once the element is in view
        }
      },
      { threshold: 0.1 } // Trigger when at least 10% of the element is visible
    );

    if (viewRef.current) {
      observer.observe(viewRef.current);
    }

    return () => {
      if (observer && observer.unobserve && viewRef.current) {
        observer.unobserve(viewRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if (inView && data?.unstructs) {
      const duration = 1500; // 1.5 seconds
      const frameDuration = 1000 / 60; // Assuming 60 FPS for smoother increment

      const increment = (target, setter) => {
        const totalFrames = duration / frameDuration;
        const incrementPerFrame = target / totalFrames;
        let current = 0;
        const timer = setInterval(() => {
          current += incrementPerFrame;
          setter(Math.min(Math.round(current), target)); // Ensure it does not exceed target
          if (current >= target) {
            clearInterval(timer);
          }
        }, frameDuration);
      };

      increment(data.unstructs.experience || 0, setExp);
      increment(data.unstructs.happyClients || 0, setHappyClients);
    }
  }, [inView, data]);


  return (
    <div className="expContainer" ref={viewRef}>
      {/* div box */}
      <div className="empInc">
        <h1 >+ {exp} years</h1>
        <p>Experience</p>
      </div>

      {/* happy */}
      <div className="empInc">
        <h1>{happyClients}k</h1>
        <p>SQ MTRS CARPETED</p>
      </div>

      {/* label step */}
      <div className="empInc" >
        <a href="https://label-step.org/" target="_" >
          <img src="/image/step.png" alt="Fair Trade Certified" className="step"/>
        </a>
        <br/>
        <br/>
        <p>Fair Trade Certified</p>
      </div>
    </div>
  );
};
