export const getFunction = async (relation, set, url) => {
  try {
    const res = await fetch(`${url}/get-item/${relation}`);
    if (!res.ok) {
      throw new Error("Internal Server Error");
    }
    const data = await res.json();
    return set(data);
  } catch (error) {
    return alert(error);
  }
};

export const getList = async (list, page, url) => {
  try {
    const res = await fetch(`${url}/get-item/${list}`, {
      headers: {
        page: page,
      },
    });
    if (!res.ok) {
      throw new Error("Internal Server Error");
    }
    const data = await res.json();
    return data?.dataLists;
  } catch (error) {
    return alert(error);
  }
};

export const getSub = async (relation, set, url) => {
  try {
    const res = await fetch(`${url}/get-sub-page-data/${relation}`);
    if (!res.ok) {
      throw new Error("Internal Server Error");
    }
    const data = await res.json();
    return set(data);
  } catch (error) {
    return alert(error);
  }
};
