import React, { useEffect, useRef, useState } from "react";
import "./index.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Nav } from "./Nav/Nav";
import { Changes } from "./GlobalVars/GlobalVars";
import { Home } from "./Home/Home";
import { About } from "./About/About";
import { Services } from "./ServicePage/Services";
import { Contact } from "./Contact/Contact";
import { Blog } from "./Blog/Blog";
import { CommunityPage } from "./Community/CommunityPage";
import { getFunction } from "./GlobalVars/GetFunction";
import { SubPage } from "./SubPages/SubPage";
import { LoginPage } from "./ReUsables/LoginPage";
import { LoginForm, RegForm } from "./ReUsables/LoginForm";
import { Workshop } from "./Workshop/WorkShop";
import { MainAdminBox } from "./Admin/MainAdminBox";
import { EditHome } from "./Admin/home/EditHome";
import { EditService } from "./Admin/service/EditService";
import { EditComm } from "./Admin/community/EditComm";
import { EditBlog } from "./Admin/blog/EditBlog";
import { EditWS } from "./Admin/workshop/EditWS";
import { EditAbt } from "./Admin/about/EditAbt";
import { EditCnt } from "./Admin/contact/EditCnt";
import { MainProf } from "./Profile/MainProf";
import { FullAdd } from "./QuillHandle/FullAdd";
import { OpenStudio } from "./openStudio/OpenStudio";
import { OS } from "./ReusableAdmin/OS";
import { EditUsers } from "./Admin/users/EditUsers";
import { Footer } from "./Footer/Footer";
import { AdFooter } from "./Admin/footer/AdFooter";
import { Products } from "./OurProducts/Products";
import { EditProduct } from "./Admin/product/EditProduct";
import { ChangePwd } from "./Admin/ChangePwd/ChangePwd";

const App = () => {
  // make url
  const url = process.env.REACT_APP_SERVER;
  const [login, setLogin] = useState("login");

  // set changes
  const [change, setChange] = useState(true);
  const [userData, setUserData] = useState({
    token: "",
    data: "",
  });
  // navigation links
  const [links, setlinks] = useState();

  useEffect(() => {
    const sessionData = JSON.parse(sessionStorage.getItem("userdata"));
    if (sessionData && sessionData?.data) {
      setUserData(sessionData);
      setLogin("profile");
    }

    getFunction("navigations-link", setlinks, url);
  }, [url]);

  const commRef = useRef(null);

  return (
    <Changes.Provider
      value={{ change, setChange, userData, setUserData, login, setLogin }}
    >
      <BrowserRouter>
        <Nav />
        <Routes>
          {/* direct Pages */}
          <Route path="/" element={<Home />} />
          <Route path="*" element={<Home />} />
          <Route path="/about" element={<About reff={commRef} />} />
          <Route path="/services" element={<Services />} />
          <Route path="/our-products" element={<Products />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/community" element={<CommunityPage />} />
          <Route path="/workshops" element={<Workshop />} />
          <Route path="/open studio" element={<OpenStudio />} />
          <Route path="/login" element={<LoginPage form={<LoginForm />} />} />
          <Route path="/register" element={<LoginPage form={<RegForm />} />} />
          {/* user or admin */}
          {Object.keys(userData?.data)?.length > 0 && (
            <Route
              path="/profile"
              element={
                userData?.token ? (
                  <MainAdminBox page={<EditHome />} />
                ) : (
                  <MainProf
                    status={"Order Status"}
                    link={userData?.data?.status}
                  />
                )
              }
            />
          )}
          {Object.keys(userData?.data)?.length > 0 && (
            <Route
              path="/profile/files"
              element={
                <MainProf status={"Files"} link={userData?.data?.files} />
              }
            />
          )}
          {Object.keys(userData?.data)?.length > 0 && (
            <Route
              path="/profile/price-rates"
              element={
                <MainProf status={"Price Rates"} link={userData?.data?.price} />
              }
            />
          )}
          {userData?.token && (
            <Route
              path="/profile/services"
              element={<MainAdminBox page={<EditService />} />}
            />
          )}{" "}
          {userData?.token && (
            <Route
              path="/profile/products"
              element={<MainAdminBox page={<EditProduct />} />}
            />
          )}
          {userData?.token && (
            <Route
              path="/profile/open studio"
              element={<MainAdminBox page={<OS />} />}
            />
          )}
          {userData?.token && (
            <Route
              path="/profile/community"
              element={<MainAdminBox page={<EditComm />} />}
            />
          )}
          {userData?.token && (
            <Route
              path="/profile/blogs"
              element={<MainAdminBox page={<EditBlog />} />}
            />
          )}
          {userData?.token && (
            <Route
              path="/profile/users"
              element={<MainAdminBox page={<EditUsers />} />}
            />
          )}
          {userData?.token && (
            <Route
              path="/profile/workshops"
              element={<MainAdminBox page={<EditWS />} />}
            />
          )}
          {userData?.token && (
            <Route
              path="/profile/about"
              element={<MainAdminBox page={<EditAbt />} />}
            />
          )}
          {userData?.token && (
            <Route
              path="/profile/footer"
              element={<MainAdminBox page={<AdFooter />} />}
            />
          )}
          {userData?.token && (
            <Route
              path="/profile/contact"
              element={<MainAdminBox page={<EditCnt />} />}
            />
          )}
          {userData?.token && (
            <Route
              path="/profile/add-services"
              element={
                <MainAdminBox
                  page={<FullAdd relation={"services"} page={"services"} />}
                />
              }
            />
          )}
          {userData?.token && (
            <Route
              path="/profile/add-products"
              element={
                <MainAdminBox
                  page={<FullAdd relation={"products"} page={"products"} />}
                />
              }
            />
          )}
          {userData?.token && (
            <Route
              path="/profile/change_password"
              element={<MainAdminBox page={<ChangePwd />} />}
            />
          )}
          {userData?.token && (
            <Route
              path="/profile/add-blog"
              element={
                <MainAdminBox
                  page={<FullAdd relation={"blog"} page={"blogs"} />}
                />
              }
            />
          )}
          {userData?.token && (
            <Route
              path="/profile/add-open-studio"
              element={
                <MainAdminBox
                  page={
                    <FullAdd
                      relation={"os"}
                      page={"open studio"}
                      isReq={false}
                    />
                  }
                />
              }
            />
          )}
          {userData?.token && (
            <Route
              path="/profile/add-communities"
              element={
                <MainAdminBox
                  page={<FullAdd relation={"community"} page={"communities"} />}
                />
              }
            />
          )}
          {userData?.token && (
            <Route
              path="/profile/add-workshop"
              element={
                <MainAdminBox
                  page={<FullAdd relation={"workshop"} page={"workshops"} />}
                />
              }
            />
          )}
          {/* sub pages */}
          {links?.service?.map((item, index) => (
            <Route
              path={`/services/${item?._id}`}
              key={index}
              element={<SubPage type={"services"} link={item?._id} />}
            />
          ))}
          {links?.blog?.map((item, index) => (
            <Route
              path={`/blog/${item?._id}`}
              key={index}
              element={<SubPage type={"blog"} link={item?._id} />}
            />
          ))}
        </Routes>
        <Footer />
      </BrowserRouter>
    </Changes.Provider>
  );
};

export default App;
