import React, { useContext } from "react";
import "./home.css";
import { HomeData } from "../GlobalVars/GlobalVars";
import { useNavigate } from "react-router-dom";

export const Abt = ({ url }) => {
  const { data } = useContext(HomeData);
  const nav = useNavigate();

  return (
    <div className="abtHome">
      <center>
        <h1>ABOUT US</h1>
      </center>
      <div className="flexAbt">
        <div className="abouttext">
          <div
            className="homeInnerContents"
            dangerouslySetInnerHTML={{ __html: data?.unstructs?.addiContent }}
          ></div>
          <br />
          <button
            className="learn"
            onClick={() => {
              nav("/about");
            }}
          >
            Learn More
          </button>
        </div>
        <div
          style={{
            backgroundImage: `url("${url}${data?.unstructs?.primaryImage}")`,
          }}
          className="homeImg"
        />
      </div>
    </div>
  );
};
