import React, { useContext, useEffect, useState } from "react";
import { AdminTopHandle } from "../../ReusableAdmin/AdminTopHandle";
import { Changes } from "../../GlobalVars/GlobalVars";
import { getFunction } from "../../GlobalVars/GetFunction";
import { ServAdminCard } from "../../ReusableAdmin/ServCard";
import { AddBtn } from "../../ReusableAdmin/AddBtn";
import JoditEditor from "jodit-react";
import { editUnits } from "../../GlobalVars/AdminPost";

export const EditProduct = () => {
  const [data, setData] = useState();
  const url = process.env.REACT_APP_SERVER;
  const { change, setChange, userData } = useContext(Changes);

  useEffect(() => {
    getFunction("product", setData, url);
  }, [url, change]);

  const [topContent, setTopContent] = useState({
    title: "",
    subtitle: "",
  });
  useEffect(() => {
    if (data) {
      return setTopContent({
        title: data?.unstructs?.title || "",
        subtitle: data?.unstructs?.subtitle || "",
      });
    }
  }, [data]);

  return (
    <div className="adminEdit">
      <AdminTopHandle data={data?.unstructs?.primaryImage} relate={"product"} />
      <br />
      <br />
      <br />

      <form
        className="innerContents"
        onSubmit={async (e) => {
          e.preventDefault();
          const isSuccess = await editUnits(
            "product",
            topContent,
            userData?.token
          );
          if (isSuccess) {
            return alert("Edited Successfully");
          } else {
            return alert("Failed To Edit!");
          }
        }}
      >
        <input
          type="text"
          className="inputTitle"
          placeholder="Enter Title"
          value={topContent?.title}
          onChange={(e) => {
            setTopContent((prev) => ({ ...prev, title: e?.target?.value }));
          }}
        />
        <br />
        <br />
        <JoditEditor
          value={topContent?.subtitle}
          onChange={(val) => {
            setTopContent((prev) => ({ ...prev, subtitle: val }));
          }}
        />
        <br />
        <input type="submit" className="submit" />
      </form>

      {/* breaking line */}
      <br />
      <br />

      {/* mapping services card */}

      <AddBtn linkTo={"/profile/add-products"} />
      <div
        className="servHolder"
        style={{ margin: "0 auto", width: "calc(80%)", textAlign: "center" }}
      >
        {data?.product?.map((item, index) => (
          <ServAdminCard
            data={item}
            url={url}
            change={change}
            setChange={setChange}
            key={index}
            relation={"product"}
          />
        ))}
      </div>
    </div>
  );
};
