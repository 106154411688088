import React from "react";
import "./reuse.css";
import { TfiLayoutGrid3Alt} from "react-icons/tfi";
import { TiThList } from "react-icons/ti";

export const GridControl = ({ curr, set }) => {
  return (
    <div className="gridControllers">
      <button
        className={`grid ${!curr && "activeGrid"}`}
        onClick={() => {
          set(false);
        }}
      >
        <TiThList />
      </button>
      <button
        className={`grid ${curr && "activeGrid"}`}
        onClick={() => {
          set(true);
        }}
      >
        <TfiLayoutGrid3Alt />
      </button>
    </div>
  );
};
