import React from "react";
import "./reuse.css";
import { useNavigate } from "react-router-dom";

export const ServCard = ({ data, url, style, isProduct }) => {
  const nav = useNavigate();

  return (
    <div
      className="servCards"
      style={style}
      onClick={() => {
        if (!isProduct) {
          nav(`/services/${data?._id}`);
        }
      }}
    >
      <div className="imageHolderServ">
        <img src={url + data?.image} alt="" className="servImg" />
      </div>
      <div className="textOfServ" style={isProduct? {
        height:"10rem"
      }:{}}>
        <font style={isProduct?{padding:"0"}:{}}>{data?.title}</font>
      </div>
    </div>
  );
};
