import React from "react";
import "./reuse.css";
export const LoginPage = ({ form }) => {
  return (
    <div className="loginMain">
      <div style={{backgroundImage:`url("/image/login.png")`}} className="img"/>
      <div className="mainLoginForm">{form}</div>
    </div>
  );
};
