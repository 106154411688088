import React, { useContext } from "react";
import "./comp.css";
import { Changes } from "../../../GlobalVars/GlobalVars";

export const UrlForm = ({ set, reset, idx , val}) => {

  const { userData, setChange } = useContext(Changes);

  const submit = async (e) => {
    e.preventDefault();
    try {
      const res = await fetch(process.env.REACT_APP_SERVER+"/edit-slide-url", {
        method: "PUT",
        headers: {
          authorization: `Bearer ${userData?.token}`,
          "Content-Type":"application/json"
        },
        body: JSON.stringify({
          index: idx,
          url: e?.target?.url?.value
        }),
      });

      const data = await res.json();
      if (res.ok) {
        await reset(false);
        await setChange(prev=>!prev)
        return alert(data?.msg);
      } else {
        throw new Error(data);
      }
    } catch (error) {
      console.log(error);
      await reset(false);
      return alert(error?.msg);
    }
  };

  return (
    <div className="formContainer" style={{ top: set ? "0" : "-100vh", zIndex:"100" }}>
      <form className="urlForm" onSubmit={submit} style={{maxWidth:"40rem"}}>
        <h1>For Slide {idx + 1}</h1>
        <input
          type="text"
          className="input"
          style={{ width: "calc(100% - 3rem)" }}
          placeholder="Enter Hyper Link"
          id="url"
          name="url"
          defaultValue={val?.[idx]?.url}
        />
        <br />
        <br />
        <input type="submit" className="submit" />
        &nbsp;&nbsp;&nbsp;&nbsp;
        <button
          type="reset"
          className="submit"
          style={{ background: "red", borderColor: "red" }}
          onClick={() => {
            reset(false);
          }}
        >
          Cancel
        </button>{" "}
      </form>
    </div>
  );
};
