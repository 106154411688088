import React, { useContext, useLayoutEffect, useState } from "react";
import "./home.css";
import { Changes, HomeData } from "../GlobalVars/GlobalVars";
import { getFunction } from "../GlobalVars/GetFunction";
import { SlideImage } from "./SlideImage";
import { Exp } from "./Exp";
import { Serv } from "./Serv";
import { Abt } from "./Abt";
import { Industry } from "./Industry.jsx";
import { Products } from "./Products.jsx";

export const Home = () => {
  const [data, setData] = useState();
  const { change } = useContext(Changes);
  const url = process.env.REACT_APP_SERVER;

  useLayoutEffect(() => {
    getFunction("home", setData, url);
  }, [change, url]);

  return (
    <div className="main">
      <HomeData.Provider value={{ data }}>
        <SlideImage url={url} />
        <Exp />
        <Serv url={url} />
        <Products/>
        <Abt url={url} />
        <Industry />
      </HomeData.Provider>
    </div>
  );
};
